var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-toolbar',{attrs:{"title":_vm.$t('Operators'),"total":_vm.$store.getters['operator/total']},on:{"refresh":_vm.getItems}}),_c('v-card',{staticClass:"mb-6 mt-6"},[_c('div',{staticClass:"m-2"},[_c('v-row',{staticClass:"ml-5 mr-5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Rechercher globalement","outlined":"","dense":"","placeholder":"Rechercher globalement","hide-details":"","clearable":""},on:{"input":_vm.getItemsByFields},model:{value:(_vm.$store.state.operator.field.keyword),callback:function ($$v) {_vm.$set(_vm.$store.state.operator.field, "keyword", $$v)},expression:"$store.state.operator.field.keyword"}})],1)],1)],1)]),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.isDialogVisible,"loading-text":"Chargement..","headers":_vm.headers,"items":_vm.$store.getters['operator/operators'],"items-per-page":_vm.itemsPerPage,"page":_vm.currentPage,"dense":"","fixed-header":"","height":"500","hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.currentPage = $event}},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.logo_path ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.logo_path ? '' : 'primary',"size":"32"}},[(item.logo_path)?_c('v-img',{attrs:{"src":("" + (item.logo_path))}}):(item.label)?_c('span',[_vm._v(_vm._s(item.label ? item.label.slice(0,2).toUpperCase() : null))]):_c('v-img',{attrs:{"src":require('@/assets/images/avatars/1.png')}})],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.label))]),_c('small',[_vm._v(_vm._s(item.code))])])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.created_at))))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.updated_at))))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((item.is_active ? 'primary' : 'error') + "--text"),attrs:{"small":"","color":item.is_active ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(item.is_active === true ? 'OUI' : 'NON')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('buttons-group',{attrs:{"item":item,"lock":false,"status":false,"remove":false,"activate":true},on:{"view":function($event){return _vm.openItem(item)},"activate":function($event){return _vm.deleteItem(item)},"edit":function($event){return _vm.openForm(item)}}})]}}],null,true)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-text-field',{attrs:{"label":"Lignes par page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":_vm.getItemsPerPage},model:{value:(_vm.$store.state.operator.field.paginate),callback:function ($$v) {_vm.$set(_vm.$store.state.operator.field, "paginate", $$v)},expression:"$store.state.operator.field.paginate"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.lastPage},on:{"input":_vm.getItems},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('operator-form-dialog',{attrs:{"dialog":_vm.dialog,"operator":_vm.operator},on:{"clickout":_vm.closeDialog,"submitted":_vm.loading}}),_c('confirm-dialog',{attrs:{"message":_vm.confirmMessage,"dialog-delete":_vm.dialogDelete},on:{"clickout":_vm.closeDelete,"submitted":_vm.activateItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }